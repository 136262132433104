import * as React from "react";

import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../templates/layout";

const Tips = () => {
  return (
    <>
      <Layout>
        <main>
          <title>Tips</title>

          <h1>Tips</h1>

          <div className="list">
            <Link to="https://www.skillsyouneed.com/learn/exam-tips.html">
            Top Exam Preparation Tips | SkillsYouNeed
            </Link>
            <Link to="https://www.ozstudies.com/blog/australia-study-tips/top-exam-preparation-tips">
            Top 15 Exam Preparation Tips: Key To Success - OzStudies
            </Link>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Tips;
